/* General */

* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

button {
    cursor: pointer;
}

p {
    color: white;
}

a,
li,
ul {
    text-decoration: none;
    list-style: none;
}

body {
    color: white;
    --tw-bg-opacity: 1;
    background-color: black;
}

section {
    margin: 10rem 5rem;
}

main {
    overflow-x: hidden;
}

/* Navbar */

.navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: fixed;
    z-index: 100;
    width: 100%;
    padding: 0rem 6rem;
    height: 170px;
    top: 0;
    background-color: black;
}

.nav-links a {
    color: white;
    font-weight: 500;
}

.logo {
    width: 12rem;
    margin-top: 6px;
}

.menu {
    display: flex;
    gap: 1em;
    font-size: 18px;
}

input[type=checkbox] {
    display: none;
}

.hamburger {
    color: white;
    padding-left: 32px;
    display: none;
    font-size: 24px;
    user-select: none;
}

@media (max-width: 768px) {
    .menu {
        display: none;
        position: absolute;
        background-color: black;
        right: 0;
        top: 10rem;
        left: 0;
        text-align: center;
        padding: 16px 0;
    }

    .menu li+li {
        margin-top: 12px;
    }

    input[type=checkbox]:checked~.menu {
        display: flex;
        flex-direction: column;
    }

    .hamburger {
        display: block;
    }

    /* .dropdown {
        left: 50%;
        top: 30px;
        transform: translateX(35%);
    }

    .dropdown li:hover {
        background-color: #4c9e9e;
    } */

    .services {
        display: flex;
        justify-content: center;
    }
}

.services {
    position: relative;
}

/* .dropdown {
    background-color: rgb(38, 38, 38);
    padding: 1em 0;
    position: absolute;
    display: none;
    border-radius: 8px;
    top: 24px;
}

.dropdown li+li {
    margin-top: 10px;
}

.dropdown li {
    padding: 0.5em 1em;
    width: 8em;
    text-align: center;
}

.dropdown li:hover {
    background-color: #000000;
}

.services:hover .dropdown {
    display: block;
} */

.link-chevron {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 5rem;
}

/* Hero */

.hero {
    position: relative;
    margin-top: 6rem;
}

.each-slide>div img {
    width: 100%;
    height: calc(100vh - 6rem);
    object-fit: cover;
}

.slideshow {
    opacity: 0.3;
}

.overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: white;
    padding: 4rem;
    text-align: center;
    z-index: 2;
}

.overlay p {
    opacity: 0.7;
    font-size: 24px;
}

.overlay img {
    width: calc(100vw - 90%);
    margin-bottom: 3rem;
}

@media (max-width: 456px) {
    .overlay p {
        font-size: 18px;
    }

    .overlay img {
        margin-top: 4rem;
    }
}

/* Sección YT Embed */

.video-embed {
    display: flex;
    justify-content: center;
    width: calc(100vw - 20rem);
}

/* Seccion Fotos */

section h1 {
    font-size: 48px;
    text-align: center;
}

.section-fotos {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}

.img-box {
    width: 100px;
    height: 120px;
}

.img-box img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.contenedor-fotos {
    display: flex;
    margin-top: 4rem;
    flex-wrap: wrap;
    justify-content: center;
    align-content: center;
    gap: 32px;
}

.boton {
    margin-top: 4rem;
    background-color: black;
    color: white;
    font-size: x-large;
    padding: 12px 18px;
    border: 1px solid white;
    border-radius: 8px;
    cursor: pointer;
    font-family: 'Anton', sans-serif;
}

@media (max-width: 900px) {
    .img-box {
        width: 200px;
        height: unset;
    }
}

/* Sección Videos */

.section-videos {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}

.contenedor-videos {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-content: center;
    gap: 32px;
    margin-top: 8rem;
}

.video-box p {
    text-align: center;
    font-weight: 500;
    font-size: 18px;
    margin-bottom: 1.5rem;
}

@media (max-width: 530px) {
    .video-box iframe {
        width: 340px;
        height: 200px;
    }
}

.datos-contacto {
    margin-top: 8rem;
}

.datos-contacto h2,
.datos-contacto h4,
.datos-contacto p {
    margin-top: 2.5rem;
    text-align: center;
}

.datos-contacto h4,
.datos-contacto p {
    font-size: 24px;
}

/* Footer */

.footer {
    display: flex;
    justify-content: space-between;
}

section.footer {
    margin: 10rem 5rem 4rem;
}

.media {
    display: flex;
    gap: 10px;
}

.media svg {
    font-size: 38px;
}

@media (max-width: 680px) {
    .footer {
        flex-direction: column;
        gap: 32px;
    }

    .media {
        justify-content: center;

    }
}

/* Fechas */

.dates-container {
    margin: 200px 20vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 32px;
}

.fecha {
    display: flex;
    border: 1px solid white;
    border-radius: 4px;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    height: 10rem;
}

.css-u1kv18 {
    height: 10em !important;
}

.city-venue {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
}

.city-venue h1 {
    font-size: 42px;
}

.city-venue p {
    font-size: 24px;
}

.tickets {
    margin-right: 1rem;
    display: flex;
    flex-direction: column;
}

.tickets a {
    background-color: black;
    border: 1px solid white;
    border-radius: 8px;
    color: white;
    font-size: 16px;
    padding: 14px;
    font-weight: lighter;
}

@media (max-width: 725px) {
    .dates-container {
        margin: 200px 2vw;
    }
}

@media (max-width: 500px) {

    .city-venue h1 {
        font-size: 32px;
    }
    
    .city-venue p {
        font-size: 18px;
    }

    .tickets a {
        font-size: 12px;
        padding: 8px;
    }
}

.dates-container button {
    margin-top: 2rem;
}

.boton-borrar {
    color: white;
    padding: 8px;
    border: 1px solid white;
    background-color: black;
    border-radius: 8px;
}

/* Formulario */

.form-create {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 12px;
}

.form-create p {
    margin-bottom: 2rem;
    font-size: 32px;
}

input {
    padding: 8px;
    border: 1px solid white;
    border-radius: 8px;
    background-color: black;
    color: white;
}

input::placeholder {
    color: white;
}

.cargando {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 32px;
    margin-top: 15rem;
}